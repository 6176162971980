import Card from "@Components/Card";
import CustomerIcon from "@Components/Icons/Customer";
import Table from "@Components/Table";
import GetPath from "@Helpers/GetPath";
import { getIconFromPlatform } from "@Helpers/Network";
import { displayDate } from "@Shared/Helpers/Date";
import { CUSTOMER, CUSTOMER_USAGE } from "@Shared/Paths/Admin";
import React from "react";
import { NavLink } from "react-router-dom";
export default class SensabillCustomers extends React.Component {
    render() {
        const fields = [
            {
                field: "id",
            },
            {
                field: "customerId",
                hidden: true,
                searchable: true,
            },
            {
                field: "customer",
                header: "Customer",
                searchable: true,
                noLink: true,
                alias: (usage) => {
                    const customer = this.props.customers.find((company) => company.id === usage.customerId);
                    if (customer) {
                        return customer.name;
                    }
                    return "";
                },
                display: (usage) => {
                    if (usage.customer) {
                        return (React.createElement(NavLink, { to: GetPath(CUSTOMER, usage.customerId) }, usage.customer));
                    }
                    return "";
                },
            },
            {
                field: "endsAt",
                header: "Renewal Date",
                sortable: true,
                searchable: true,
                display: (usage) => displayDate(usage.endsAt),
            },
            {
                field: "platform",
                header: "Platform",
                display: ({ platform }) => {
                    const Icon = getIconFromPlatform(platform);
                    if (Icon !== undefined) {
                        return React.createElement(Icon, null);
                    }
                },
            },
        ];
        const filteredData = this.props.usages.filter((usage) => {
            const tender = this.props.tenders.find(({ customerId }) => customerId === usage.customerId);
            if (tender !== undefined) {
                return false;
            }
            const customer = this.props.customers.find(({ id }) => id === usage.customerId);
            return customer?.canViewSensabill;
        });
        return (React.createElement(Card, { title: React.createElement(React.Fragment, null,
                React.createElement(CustomerIcon, null),
                " Sensabill Customers") },
            React.createElement(Table, { link: (data) => GetPath(CUSTOMER_USAGE, data.customerId, data.id), data: filteredData, fields: fields, fetching: this.props.fetching, reload: this.props.reload })));
    }
}
