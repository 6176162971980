import { Network } from "@Shared/Entities/Customer/Usage/enums";
import { Platform } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export const getNetworkFromPlatform = (platform) => {
    switch (platform) {
        case Platform.O2:
        case Platform["O2 - Plan.com"]:
            return Network.O2;
        case Platform.EE_Small_Business:
        case Platform.EE_Corporate:
        case Platform.EE_Teams:
        case Platform["EE - Plan.com"]:
            return Network.EE;
        case Platform.Vodafone_VCO:
        case Platform.Vodafone:
            return Network.Vodafone;
        case Platform.MVNO_Three:
            return Network.Three;
    }
};
