import React from "react";
import { connect } from "react-redux";
import Card from "@Components/Card";
import CustomerIcon from "@Components/Icons/Customer";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import SubHeader from "@Components/SubHeader";
import Table from "@Components/Table";
import GetPath from "@Helpers/GetPath";
import { Status } from "@Shared/Entities/AwardedContract/enums";
import { fetchAwardedContractsForSupplier } from "@Store/Actions/AwardedContract";
import { addNotification } from "@Store/Actions/Notifications";
import getAwardedContractEntities from "@Helpers/GetAwardedContractEntities";
import { getIconFromPlatform } from "@Helpers/Network";
import { CUSTOMER } from "@Shared/Paths/Supplier";
import styles from "./index.module.scss";
export const getActionPending = (status) => {
    switch (status) {
        case Status.Awaiting_Changed_Requirements:
            return "Note any changed requirements";
        case Status.Awaiting_Supplier_Documents:
            return "Upload contract";
        case Status.Awaiting_Admin_Approval:
            return "Waiting for admin approval";
        case Status.Awaiting_Customer_Documents:
            return "Waiting for customer signature";
        case Status.Awaiting_Start_Date:
            return "Input start date";
        case Status.Awaiting_Billing_Details:
            return "Input billing details";
        case Status.Awaiting_Confirmation_From_Customer:
        case Status.Complete:
            return "Complete";
    }
};
class Customers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
        };
    }
    componentDidMount() {
        this.props.fetch(this.props.user.supplierId).finally(() => {
            this.setState({ fetching: false });
        });
    }
    render() {
        if (this.state.fetching) {
            return React.createElement(LoadingFallback, null);
        }
        const fields = [
            {
                field: "awardedContractId",
                hidden: true,
            },
            {
                field: "name",
                header: "Name",
                leftAlign: true,
            },
            {
                field: "network",
                header: "Network",
                display: ({ network }) => {
                    const Icon = getIconFromPlatform(network);
                    if (Icon === undefined) {
                        return "---";
                    }
                    return React.createElement(Icon, null);
                },
            },
            {
                field: "lines",
                header: "Lines",
            },
            {
                field: "renewal",
                header: "Renewal Date",
            },
            {
                field: "status",
                header: "Action Pending",
                display: ({ status }) => getActionPending(status),
            },
        ];
        const data = this.props.awardedContracts.reduce((arrays, awardedContract) => {
            const { tender, customer, response } = getAwardedContractEntities(awardedContract.id);
            let arrayName = "inProgress";
            if (awardedContract.status === Status.Complete ||
                awardedContract.status ===
                    Status.Awaiting_Confirmation_From_Customer) {
                arrayName = "complete";
            }
            const tenderDetails = tender?.details;
            arrays[arrayName].push({
                awardedContractId: awardedContract.id,
                name: customer?.name,
                lines: tenderDetails?.totalLines,
                network: response?.meta?.primaryPlatform,
                status: awardedContract.status,
            });
            return arrays;
        }, { inProgress: [], complete: [] });
        return (React.createElement(React.Fragment, null,
            React.createElement(SubHeader, { flex: true },
                React.createElement("span", { className: styles.headerTextWon }, "In Progress "),
                React.createElement("span", { className: styles.headerTextLost }, "Complete")),
            React.createElement("div", { className: styles.actionable },
                React.createElement("div", { className: styles.toDo },
                    React.createElement("div", { className: styles.leftCard },
                        React.createElement(Card, { title: React.createElement(React.Fragment, null,
                                React.createElement(CustomerIcon, null),
                                " Customers") },
                            React.createElement(Table, { data: data.inProgress, fields: fields, fetching: this.state.fetching, link: (data) => GetPath(CUSTOMER, data.awardedContractId), className: styles.table })))),
                React.createElement(SubHeader, { zIndexAdd: 1, flex: true, className: styles.secondSubHeader },
                    React.createElement("span", { className: styles.headerTextLost }, "Complete")),
                React.createElement("div", { className: styles.complete },
                    React.createElement(Card, { title: React.createElement(React.Fragment, null,
                            React.createElement(CustomerIcon, null),
                            " Customers") },
                        React.createElement(Table, { data: data.complete, fields: fields, fetching: this.state.fetching, link: (data) => GetPath(CUSTOMER, data.awardedContractId), className: styles.table }))))));
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        notify: (data) => addNotification(dispatch, data),
        fetch: (supplierId) => fetchAwardedContractsForSupplier(dispatch, supplierId),
    };
};
const mapStateToProps = ({ auth: { user }, awardedContracts }) => ({
    user,
    awardedContracts,
});
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
