import React from "react";
import validate from "validate.js";
import Button from "@Components/Button";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import Form, { InputType } from "@Components/Form";
import FormGroup from "@Components/Form/Group";
import HoverTooltip from "@Components/HoverTooltip";
import buttons from "@Components/Sass/buttons.module.scss";
import Tickbox from "@Components/Tickbox";
import { ToastStatus } from "@Components/Toast";
import { format } from "@Shared/Helpers/Money";
import { validation } from "@Shared/Validate";
import { getIconFromNetwork } from "@Helpers/Network";
import { detailsConstraints } from "./constraints";
import styles from "./index.module.scss";
export default class FormPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            errors: {},
            companyFound: false,
            accepted: false,
        };
    }
    inputChange = (data) => {
        this.setState({ data });
    };
    inputCompanyChange = (companyData) => {
        const data = {
            ...this.state.data,
            company: { ...companyData },
        };
        this.setState({
            data: data,
        });
    };
    findCompany = () => {
        this.setState({ errors: {} });
        try {
            validation({ companyNo: this.state.data.companyNo }, {
                companyNo: {
                    presence: true,
                    type: "string",
                    format: {
                        pattern: /^([\da-z]{2})\d{6}$/i,
                        message: (value) => validate.format('^"%{num}" is not a valid company number', {
                            num: value,
                        }),
                    },
                },
            });
        }
        catch (error) {
            for (let index = 0; index < error.fails.companyNo.length; index++) {
                this.props.notify({
                    title: "Invalid input",
                    description: error.fails.companyNo[index],
                    status: ToastStatus.Error,
                });
            }
            return Promise.resolve(() => {
                this.setState({
                    errors: { companyNo: true },
                });
            });
        }
        return (this.props
            // eslint-disable-next-line unicorn/no-array-callback-reference
            .find(this.state.data.companyNo)
            .then((companyData) => {
            if (companyData.status === "dissolved") {
                this.props.notify({
                    title: "This company has been dissolved",
                    description: "Enter a valid company number",
                    status: ToastStatus.Error,
                });
                const data = {
                    ...this.state.data,
                    company: {},
                };
                this.setState({
                    data,
                    companyFound: false,
                });
                return;
            }
            const data = {
                ...this.state.data,
                company: { ...companyData },
            };
            this.setState({
                data,
                companyFound: true,
            });
        })
            .catch((error) => {
            if (error.name === "DuplicateException") {
                return this.props.notify({
                    title: "Error",
                    description: "That company is already registered",
                    status: ToastStatus.Error,
                });
            }
            return this.props.notify({
                title: "Error finding company",
                description: error.message,
                status: ToastStatus.Error,
            });
        }));
    };
    submit = () => {
        if (this.state.accepted === false) {
            return Promise.resolve(() => this.props.notify({
                title: "Error",
                description: "Terms & conditions must be accepted",
                status: ToastStatus.Error,
            }));
        }
        this.setState({ errors: {} });
        const { data } = this.state;
        const supplier = {
            ...data,
            tenderId: this.props.tenderId,
        };
        try {
            validation(supplier, detailsConstraints);
            // returns callback in case of error to update state (errors)
            return this.props.onSubmit(supplier).catch((error) => {
                this.props.notify({
                    title: "Error",
                    description: error.message,
                    status: ToastStatus.Error,
                });
                const error_ = { message: [error.message] };
                return () => {
                    this.setState({ errors: error_ });
                };
            });
        }
        catch (error) {
            Object.keys(error.fails).map((key) => {
                this.props.notify({
                    title: "Error",
                    description: error.fails[key],
                    status: ToastStatus.Error,
                });
            });
            // returns callback to update state
            return Promise.resolve(() => {
                this.setState({ errors: error.fails });
            });
        }
    };
    toggleAccepted = (value) => {
        this.setState({
            accepted: value,
        });
    };
    render() {
        const { totalLines, length, network } = this.props;
        const feePerLine = 15 * (length / 24);
        return (React.createElement(React.Fragment, null,
            React.createElement(ColouredBorderBox, { className: styles.card },
                React.createElement("div", { className: styles.tenderDetails },
                    React.createElement("div", { className: styles.item },
                        "Current Network",
                        React.createElement("div", { className: styles.value }, getIconFromNetwork(network))),
                    React.createElement("div", { className: styles.item },
                        "Number of Lines",
                        React.createElement("div", { className: styles.value }, totalLines)),
                    this.props.showFee && (React.createElement("div", { className: styles.item },
                        React.createElement("div", null,
                            "Winner's Fee",
                            React.createElement(HoverTooltip, { className: styles.tooltip }, "This is the per line fee")),
                        React.createElement("div", { className: styles.value }, format(feePerLine, feePerLine % 1 == 0 ? 0 : 2)))),
                    React.createElement("div", { className: styles.item },
                        "Contract Length",
                        React.createElement("div", { className: styles.value },
                            length,
                            " months")))),
            React.createElement(ColouredBorderBox, { className: styles.card, title: "To submit a Proposal please read below" },
                React.createElement("div", { className: styles.paragraph },
                    React.createElement("div", { className: styles.messageTitle }, "Congratulations"),
                    React.createElement("div", { className: styles.content },
                        "The Customer has invited you to tender for their next mobile contract using the Platform. You will be supported to make the best submission possible by the Platform Supplier Support Team.",
                        React.createElement("div", null, "The following rules apply on how to use the Platform:"),
                        React.createElement("ul", null,
                            " ",
                            React.createElement("li", null,
                                "Supplier Proposals will only be accepted using the Platform.",
                                " "),
                            React.createElement("li", null,
                                " ",
                                "No written Proposals sent directly to the Customer will be considered."),
                            " ",
                            React.createElement("li", null, "Any Proposal already submitted will need to be transcribed onto the Platform."),
                            React.createElement("li", null,
                                " ",
                                "All Proposals on the Platform are best and final offers")))),
                React.createElement("div", { className: styles.paragraph },
                    React.createElement("div", { className: styles.messageTitle }, "Platform Benefits"),
                    React.createElement("div", { className: styles.content }, "The Platform has been created to support Customers and Suppliers using a transparent and fair, fact based process. All Suppliers have full access to usage breakdown and analysis to support their Proposal. Customers are able to make quicker decisions and time to contract is vastly reduced. Only the winning Supplier will be charged the fair fee from the Platform owner")),
                this.props.showFee && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.paragraph },
                        React.createElement("div", { className: styles.messageTitle }, "Fees"),
                        React.createElement("div", { className: styles.content },
                            "You are not obliged to submit a Proposal. There is no fee for submitting a Proposal, but if selected by the Customer as the winning Supplier you will be invoiced by the Platform owner North Swell Technologies Limited the applicable fee",
                            React.createElement("br", null),
                            " Fees vary dependent on contract length.",
                            React.createElement("div", { className: styles.fees },
                                React.createElement("div", { className: styles.feeItem },
                                    React.createElement("div", { className: styles.title }, "12 months"),
                                    React.createElement("div", null, "\u00A37.50 per line")),
                                React.createElement("div", { className: styles.feeItem },
                                    React.createElement("div", { className: styles.title }, "24 months"),
                                    React.createElement("div", null, "\u00A315 per line")),
                                React.createElement("div", { className: styles.feeItem },
                                    React.createElement("div", { className: styles.title }, "36 months"),
                                    React.createElement("div", null, "\u00A322.50 per line"))),
                            "All fees are exclusive of VAT and subject to payment no later than 30 days after first connection date.",
                            React.createElement("br", null))))),
                React.createElement("div", { className: `${styles.paragraph} ${styles.inline}` },
                    React.createElement("b", null,
                        "Tick here to confirm that you understand and accept these terms",
                        " "),
                    React.createElement(Tickbox, { value: this.state.accepted, toggle: this.toggleAccepted })),
                React.createElement("div", { className: styles.paragraph },
                    React.createElement("div", { className: styles.messageTitle }, "Polite Notice"),
                    React.createElement("div", { className: styles.content },
                        "Please understand and respect the",
                        " ",
                        React.createElement("b", null, "Customer\u2019s choice"),
                        " in using this Platform to run their mobile communications tender. The Platform provides a level playing field for Suppliers, created with a win-win ethos. We look forward to receiving your Proposal."))),
            React.createElement(ColouredBorderBox, { className: `${styles.card} ${styles.formCard}`, title: "Register with the Platform" },
                React.createElement(Form, { change: this.inputChange, className: styles.form, data: this.state.data, buttons: false, errors: this.state.errors, submit: this.findCompany, input: [
                        {
                            label: "Name",
                            name: "name",
                            type: InputType.Text,
                            disabled: !this.state.accepted,
                        },
                        {
                            label: "Create a Password",
                            name: "password",
                            type: InputType.Password,
                            showPassword: true,
                            disabled: !this.state.accepted,
                        },
                        {
                            label: "Company Number",
                            name: "companyNo",
                            type: InputType.Text,
                            disabled: !this.state.accepted,
                        },
                    ] },
                    React.createElement("div", { className: buttons.buttonGroupRight },
                        React.createElement(Button, { type: "submit", disabled: !this.state.accepted }, "FIND COMPANY"))),
                this.state.companyFound && (React.createElement(Form, { change: this.inputCompanyChange, submit: this.submit, className: styles.form, labelLeft: true, buttons: false, data: this.state.data.company, input: [
                        {
                            label: "Company Name",
                            name: "name",
                            type: InputType.Text,
                            disabled: true,
                        },
                        {
                            label: "Address Line 1",
                            name: "addressLine1",
                            type: InputType.Text,
                            disabled: true,
                        },
                        {
                            label: "Address Line 2",
                            name: "addressLine2",
                            type: InputType.Text,
                            disabled: true,
                        },
                        {
                            label: "City",
                            name: "city",
                            type: InputType.Text,
                            disabled: true,
                        },
                        {
                            label: "Country",
                            name: "country",
                            type: InputType.Text,
                            disabled: true,
                        },
                        {
                            label: "Postcode",
                            name: "postcode",
                            type: InputType.Text,
                            disabled: true,
                        },
                    ] },
                    React.createElement(FormGroup, null,
                        React.createElement(Button, { cta: true, type: "submit", className: styles.button, disabled: !this.state.accepted }, "FINISH")))))));
    }
}
