import dayjs from "dayjs";
import React from "react";
import { downloadCustomerDocuments } from "@Api/AwardedContracts";
import Button from "@Components/Button";
import ButtonLink from "@Components/ButtonLink";
import HoverTooltip from "@Components/HoverTooltip";
import Progress from "@Components/ProgressBar";
import AwaitingResponses from "@Components/Status/AwaitingResponses";
import Draft from "@Components/Status/Draft";
import GetPath from "@Helpers/GetPath";
import { displayDate } from "@Shared/Helpers/Date";
import { format } from "@Shared/Helpers/Money";
import { getIconFromNetwork } from "@Helpers/Network";
import { EDIT_TENDER, TENDER, VIEW_RESPONSES } from "@Shared/Paths/Customer";
import { CUSTOMER_TENDER, CUSTOMER_TENDER_RESPONSES, EDIT_CUSTOMER_TENDER, } from "@Shared/Paths/Partner";
import ExpiredPopup from "./ExpiredPopup";
import styles from "./index.module.scss";
var Status;
(function (Status) {
    Status[Status["Draft"] = 0] = "Draft";
    Status[Status["Awaiting_Responses"] = 1] = "Awaiting_Responses";
    Status[Status["Ready_For_Contracting"] = 2] = "Ready_For_Contracting";
    Status[Status["Contracted"] = 3] = "Contracted";
    Status[Status["Missed_Deadline"] = 4] = "Missed_Deadline";
})(Status || (Status = {}));
export default class TenderCard extends React.Component {
    findStatus = (publishedAt, resultsViewable, customerDeadline) => {
        if (publishedAt === undefined) {
            return Status.Draft;
        }
        if (dayjs().isBefore(resultsViewable)) {
            return Status.Awaiting_Responses;
        }
        if (this.props.awardedContract === undefined) {
            if (dayjs().isAfter(customerDeadline)) {
                return Status.Missed_Deadline;
            }
            return Status.Ready_For_Contracting;
        }
        return Status.Contracted;
    };
    downloadCustomerDoc = async () => {
        const customerDocuments = await downloadCustomerDocuments(this.props.awardedContract.id, this.props.awardedContract.customerId);
        customerDocuments.map(({ tempURL }) => {
            window.open(tempURL, "", "width=1000");
        });
    };
    render() {
        const { id, details, resultsViewable, customerDeadline, publishedAt, benchmarkCost, totalSavings, } = this.props.tender;
        const status = this.findStatus(publishedAt, resultsViewable, customerDeadline);
        return (React.createElement("div", { className: styles.wrapper },
            status === Status.Missed_Deadline && React.createElement(ExpiredPopup, null),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.header },
                    React.createElement("div", null,
                        "Tender Id: ",
                        id.toUpperCase()),
                    React.createElement("div", { className: styles.icon }, status === Status.Draft ? (React.createElement(Draft, null)) : status === Status.Awaiting_Responses ? (React.createElement(AwaitingResponses, null)) : (React.createElement(React.Fragment, null)))),
                React.createElement("div", { className: `${styles.topWrapper} ${styles.infoWrapper}` },
                    React.createElement("div", { className: styles.box }, getIconFromNetwork(details.currentNetwork)),
                    React.createElement("div", { className: styles.box },
                        React.createElement("div", { className: styles.lines }, (details.voiceLines || 0) +
                            (details.dataLines || 0) +
                            (details.voiceOnlyLines || 0)),
                        "Lines"),
                    this.props.tender.usageId === undefined && (React.createElement("div", { className: styles.box },
                        React.createElement("div", { className: styles.lines }, format(benchmarkCost, 0)),
                        React.createElement(React.Fragment, null, "Est Cost"))),
                    this.props.tender.usageId !== undefined && (React.createElement("div", { className: styles.box },
                        React.createElement("div", { className: styles.lines }, format(totalSavings, 0)),
                        React.createElement(React.Fragment, null, "Est Saving")))),
                React.createElement("div", { className: styles.mainBody },
                    React.createElement("div", { className: styles.details },
                        (status === Status.Awaiting_Responses ||
                            status === Status.Ready_For_Contracting) && (React.createElement("div", { className: styles.detail },
                            React.createElement("div", { className: styles.detailTitle }, "Responses Received"),
                            React.createElement("div", { className: styles.detailValue }, this.props.responses))),
                        status === Status.Draft && (React.createElement("div", { className: styles.detail },
                            React.createElement("div", { className: styles.detailTitle }, "Progress"),
                            React.createElement("div", { className: styles.detailValue },
                                React.createElement("div", { className: styles.bar },
                                    React.createElement(Progress, { index: 50, completeValue: 100 })),
                                React.createElement("div", { className: styles.label }, "50%")))),
                        status === Status.Awaiting_Responses && (React.createElement("div", { className: styles.detail },
                            React.createElement("div", { className: styles.detailTitle }, "Progress"),
                            React.createElement("div", { className: styles.detailValue }, "Published"))),
                        dayjs().isBefore(resultsViewable) && (React.createElement("div", { className: styles.detail },
                            React.createElement("div", { className: styles.detailTitle },
                                "Supplier Deadline",
                                React.createElement(HoverTooltip, { className: styles.tooltip }, "This is the deadline for suppliers to respond to your tender.")),
                            React.createElement("div", { className: styles.detailValue }, resultsViewable === undefined
                                ? "TBD"
                                : displayDate(resultsViewable)))),
                        this.props.tender.resultsAvailable && (React.createElement("div", { className: styles.detail },
                            React.createElement("div", { className: styles.detailTitle },
                                "Response Expiry",
                                React.createElement(HoverTooltip, { className: styles.tooltip }, "This is the deadline for you to select a supplier.")),
                            React.createElement("div", { className: styles.detailValue }, customerDeadline === undefined
                                ? "TBD"
                                : displayDate(customerDeadline))))),
                    this.props.partner ? (React.createElement("div", { className: styles.buttonGroupCenter },
                        this.props.awardedContract !== undefined && (React.createElement(Button, { click: this.downloadCustomerDoc, cta: true }, "VIEW SIGNED DOCUMENTS")),
                        status === Status.Draft && (React.createElement(ButtonLink, { to: GetPath(EDIT_CUSTOMER_TENDER, this.props.tender.customerId, id) }, "CONTINUE")),
                        React.createElement(ButtonLink, { to: GetPath(CUSTOMER_TENDER, this.props.tender.customerId, id), dark: true }, "VIEW DETAILS"),
                        status === Status.Ready_For_Contracting &&
                            this.props.tender.resultsAvailable && (React.createElement(ButtonLink, { to: GetPath(CUSTOMER_TENDER_RESPONSES, this.props.tender.customerId, id), cta: true }, "VIEW RESPONSES")))) : (React.createElement("div", { className: styles.buttonGroupCenter },
                        this.props.awardedContract !== undefined && (React.createElement(Button, { click: this.downloadCustomerDoc, cta: true }, "VIEW SIGNED DOCUMENTS")),
                        status === Status.Draft && (React.createElement(ButtonLink, { to: GetPath(EDIT_TENDER, id) }, "CONTINUE")),
                        React.createElement(ButtonLink, { to: GetPath(TENDER, id), dark: true }, "VIEW DETAILS"),
                        status === Status.Ready_For_Contracting &&
                            this.props.tender.resultsAvailable && (React.createElement(ButtonLink, { to: GetPath(VIEW_RESPONSES, id), cta: true }, "VIEW RESPONSES"))))))));
    }
}
