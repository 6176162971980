import React from "react";
import ButtonLink from "@Components/ButtonLink";
import Progress from "@Components/ProgressBar";
import GetPath from "@Helpers/GetPath";
import { Status } from "@Shared/Entities/AwardedContract/enums";
import { platformToNetwork } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import { getIconFromNetwork } from "@Helpers/Network";
import { CUSTOMER, PREVIOUS_USAGE } from "@Shared/Paths/Supplier";
import { getActionPending } from "../../Apps/Supplier/Pages/Customers/Customers";
import styles from "./index.module.scss";
export default class ContractBox extends React.Component {
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.responses }, this.props.awardedContracts.map(({ fetching, tender, response, awardedContract, customer, supplier, }) => {
                if (fetching) {
                    return React.createElement(LoadingFallback, null);
                }
                return (React.createElement("div", { className: `${styles.tenderBoxes}`, key: awardedContract.id },
                    React.createElement("div", { className: styles.wrapper },
                        React.createElement("div", { className: styles.content },
                            React.createElement("div", { className: styles.title }, "Customer Name"),
                            React.createElement("div", { className: styles.status }, customer.name)),
                        React.createElement("div", { className: styles.content },
                            React.createElement("div", { className: styles.splitContent }, getIconFromNetwork(platformToNetwork(response?.meta
                                ?.primaryPlatform))),
                            React.createElement("div", { className: styles.splitContent },
                                React.createElement("div", { className: styles.status }, tender.details.totalLines),
                                "Lines"),
                            React.createElement("div", { className: styles.splitContent },
                                "Progress",
                                React.createElement("div", { className: styles.progressBar },
                                    React.createElement(Progress, { index: awardedContract.status, completeValue: 8 })))),
                        React.createElement("div", { className: `${styles.content} ${styles.status}` },
                            awardedContract.status !==
                                Status.Complete &&
                                awardedContract.status !==
                                    Status.Awaiting_Confirmation_From_Customer
                                ? "To Do"
                                : "",
                            React.createElement("div", { className: styles.status }, getActionPending(awardedContract.status)))),
                    React.createElement("div", { className: styles.wrapper },
                        React.createElement("div", { className: styles.footer },
                            React.createElement(ButtonLink, { to: GetPath(CUSTOMER, awardedContract.id) }, "CONTINUE"),
                            supplier.canViewSensabill && (React.createElement("div", null,
                                React.createElement(ButtonLink, { to: GetPath(PREVIOUS_USAGE, awardedContract.id), target: "_blank" }, "SENSABILL")))))));
            }))));
    }
}
